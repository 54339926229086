<div class="content" role="main">
  Number of outstanding requests {{counter}}
  <button mat-fab #home extended (click)="clicked($event)">
    <mat-icon>home</mat-icon>
    Home
  </button>  
   
</div>  

<router-outlet></router-outlet>
