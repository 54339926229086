import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, takeLast } from 'rxjs';

export interface Result {
  result: string;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceConnectionService {
  constructor(private http: HttpClient) {

   }

   async aaa(): Promise<Result> { 
    var res= this.http.get<Result>('/test');
    return await firstValueFrom(res);
   }
}
