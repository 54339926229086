import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatButton } from '@angular/material/button'; 
import { DeviceConnectionService } from './device-connection.service';
import AwaitLock from 'await-lock';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('home') homeButton!: MatButton
  @ViewChild('home2') homeButton2!: MatButton
  title = 'web';
  counter = 0;
  private lock: AwaitLock = new AwaitLock();

  constructor(private connection: DeviceConnectionService) {
  }

  ngAfterViewInit() {
    console.log('check');
  }

  async clicked(event: Event) {
    this.counter++;
    await this.lock.acquireAsync();
    try {
      console.log("test");
      this.title = "clicked";
      console.log("before");
      console.log((await this.connection.aaa()).result);
      await delay(3000);
      console.log("after");
    } finally {
      this.lock.release();
      this.counter--; 
    }
  }
}
